<template>
  <div>
    <b-form-group v-if="!updated">
      <p class="color-grey mb-0" >Total Payable<br />
      <strong class="font-size-24">{{ totalPayable | formatAmount(currency(transaction).value) }}</strong></p>
    </b-form-group>
    <b-form-group v-if="updated">
      <p class="color-grey mb-0" >Total Payable<br />
      <strong class="font-size-24">{{ totalPayable - transaction.linked[0].id.amounts.paid | formatAmount(currency(transaction).value) }}</strong></p>
    </b-form-group>
    <b-form-group v-if="updated">
      <p class="color-grey mb-0">Previous payment<br />
      <strong class="text-danger">- {{ transaction.linked[0].id.amounts.payable | formatAmount(currency(transaction).value) }}</strong></p>
      <small v-if="updated && transaction.linked[0].id.payment_status === 'Verifying'">Previous payment is still being verified. If it is not successful, full payment will be required in order to fund the updated transaction.</small>
    </b-form-group>
    <b-form-group v-if="transaction">
      <p class="color-grey mb-0">Transaction total<br />
      <strong>{{ transaction.amounts.amount | formatAmount(currency(transaction).value) }}</strong></p>
    </b-form-group>
    <b-form-group v-if="buyerSplit.fee > 0">
      <p class="color-grey mb-0">My portion of fees<br />
      <strong>{{ buyerFee * (1 + currency(transaction).vat) | formatAmount(currency(transaction).value) }}</strong></p>
      <small>Fee: {{ buyerFee | formatAmount(currency(transaction).value) }} | VAT: {{ buyerFee * (currency(transaction).vat) | formatAmount(currency(transaction).value) }}</small>
    </b-form-group>
    <b-form-group v-if="buyerComm">
      <p class="color-grey mb-0">My portion of commission<br />
      <strong>{{ buyerComm.fee | formatAmount(currency(transaction).value) }}</strong></p>
    </b-form-group>
    <b-form-group>
      <p class="color-grey mb-0">
        Payment reference<br />
        <strong v-if="selected.method.name === 'EFT' && currency(transaction).value !== 'ZAR'">
          Reference will be emailed after clicking "Fund". Please ensure you use this emailed payment reference when making the EFT Payment.
        </strong>
        <strong v-else>{{ transaction ? transaction.reference : null }}</strong>
      </p>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  data () {
    return {
      selected: null,
      buyerSplit: null
    }
  },
  computed: {
    ...mapGetters('currency', {
      currencyObject: 'fullObject'
    }),
    ...mapState({
      record: state => state.transaction,
      transaction: state => state.transaction.transaction
    }),
    loadStatus () {
      return this.record.status
    },
    buyerPercentage () {
      if (this.transaction) {
        return this.transaction.amounts.fee_payable_by.buyer_percentage
      } else {
        return 0
      }
    },
    buyerFee () {
      if (this.transaction) {
        return this.selected.fee * this.transaction.amounts.fee_payable_by.buyer_percentage / 100
      } else {
        return 0
      }
    },
    buyerComm () {
      if (this.transaction) {
        if (this.buyerSplit.agent_comm && this.buyerSplit.agent_comm.length > 0) {
          return this.buyerSplit.agent_comm[0]
        } else {
          return null
        }
      } else {
        return null
      }
    },
    totalPayable () {
      if (this.transaction) {
        let _total = this.buyerFee * (1 + this.currency(this.transaction).vat)
        _total += this.transaction.amounts.amount
        _total += this.buyerComm ? this.buyerComm.fee : 0
        return _total
      } else {
        return 0
      }
    },
    updated () {
      if (this.transaction) {
        return this.transaction.linked && this.transaction.linked.length > 0 && this.transaction.linked[0].id.payment_status !== 'Pending'
      } else {
        return false
      }
    },
    currentRoute () {
      return this.$route.params.method
    }
  },
  methods: {
    currency: function (transaction) {
      return this.currencyObject(transaction ? transaction.amounts.currency : 'ZAR')
    }
  },
  created () {
    if (this.loadStatus === 'loaded') {
      this.selected = this.transaction.amounts.fees.find(fee => (typeof fee.method === 'object' && fee.method !== null ? fee.method.name.toLowerCase() : fee.method.toLowerCase()) === this.$route.params.method)
      this.buyerSplit = this.transaction.amounts.split.find(split => split.actor === 'Buyer')
    }
  },
  watch: {
    currentRoute () {
      if (this.loadStatus === 'loaded') {
        this.selected = this.transaction.amounts.fees.find(fee => (typeof fee.method === 'object' && fee.method !== null ? fee.method.name.toLowerCase() : fee.method.toLowerCase()) === this.$route.params.method)
        this.buyerSplit = this.transaction.amounts.split.find(split => split.actor === 'Buyer')
      }
    }
  }
}
</script>
